.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: auto;
    margin: 25px 14px;
    text-align: center;   
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
  
/* scrollbar */
.scroll-bar::-webkit-scrollbar-track
{
	border-radius: 8px;
	background-color: #EEEEEE;
}

.scroll-bar::-webkit-scrollbar
{
	width: 7px;
	background-color: #EEEEEE;
}

.scroll-bar::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	background-color: #BBBBBB;
}







  .ant-table-body::-webkit-scrollbar,
  .ant-table::-webkit-scrollbar,
  .ant-table-container::-webkit-scrollbar,
  .ant-table-content::-webkit-scrollbar {
    width: 7px;
	background-color: #EEEEEE;
  height: 5px; 
  border-radius: 8px;
  }
  
  .ant-table-body::-webkit-scrollbar-thumb,
  .ant-table::-webkit-scrollbar-thumb,
  .ant-table-container::-webkit-scrollbar-thumb,
  .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #BBBBBB;
  }

  .roundedImg{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
  }

  .btnCheck .ant-space-item{
    width: 100%;
  }

  .checkAll{
    background-color:#7fb691;
    padding:10px;
    border-radius:10px;
    height:42px;
    width:100%;
    display: block;
    color: #fff;
  }

  .border-0-btn{
    border: 0px !important;
    padding: 0px !important;
  }

  .listitem li{
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    border-radius: 0px !important;
    color: #fff;
  }



  