.w-50{
    width: 60%;
    margin: auto;
    margin-bottom: 10px;
    max-width: 100%;
    display: block;
}

.w-50 .ant-upload-text{
    font-size: 12px;
}
.csv-reader-space {
    flex-direction: row;
  }
  
@media (max-width: 940px) {
    .csv-reader-space {
      flex-direction: column;
    }
}